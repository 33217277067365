import React from "react";

import Usp from "../USP";
import Button from "../Button";
import { WhyPanelWrapper } from "./style";

const WhyPanel = () => {
  return (
    <WhyPanelWrapper id="services">
      <div className="inner-wrapper">
        <div className="text-wrapper">
          <h2>Why choose us?</h2>
          <h3>Best performance for you and your online visitors</h3>
          <p>
            Our unique approach in utilising the combination of Gatsby.js and Wordpress CMS brings your business more
            benefits.
          </p>
        </div>
        <Usp id="usp-section" />

        <Button
          buttonWrapClass="btn-wrapper"
          linkTo="/#contact"
          buttonText="Request a free consultation"
          buttonClass="primary"
        />
      </div>
    </WhyPanelWrapper>
  );
};

export default WhyPanel;
