import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { master } from "../../resources";
import { PortfolioWrapper } from "./style";

const thePortfolio = master.portfolioArray;

const Portfolio = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 745,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  return (
    <PortfolioWrapper className="portfolio-wrapper">
      <div className="portfolio-inner">
        <Slider {...settings}>
          {thePortfolio.map((object, objectIndex) => {
            const { companyName, companyBrief, summaryPoints, quote, title, name, alt, image } = object;

            return (
              <div key={objectIndex} className="portfolio-group">
                <div className="col-1">
                  <div className="col-inner">
                    <div className="company-info">
                      <h3 className="company-name">{companyName}</h3>
                      <p className="company-brief">{companyBrief}</p>
                    </div>
                    <div className="summary-points">
                      <ul>
                        {summaryPoints.map((list, listsIndex) => {
                          const { point } = list;

                          return (
                            <li key={listsIndex}>
                              <span className="icon-wrapper">
                                <i className="custom-icon icon-check">&#xe806;</i>
                              </span>
                              <span className="list-text">{point}</span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="quote-wrapper">
                      <div className="quote-top">
                        <q>{quote}</q>
                      </div>
                      <div className="quote-bottom">
                        <span className="title">{title}</span>
                        <span className="name">{name}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-2">
                  <div className="col-inner">
                    <div className="image-wrapper">
                      <img alt={alt} src={image} className="portfolio-image"></img>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </PortfolioWrapper>
  );
};

export default Portfolio;
