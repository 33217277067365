import styled, { css } from "styled-components";

import { displayFlex, hoverTransition, onHoverFocus } from "../Global/style-variables";

export const ButtonWrapper = styled.div`
  margin-right: 2rem;
  margin-bottom: 1rem;

  a {
    display: inline-block;

    .contents-wrapper {
      ${displayFlex("space-between", "center")};
      ${hoverTransition};
      border: 2px solid ${(props) => props.theme.colors.mainPurple};
      border-radius: 5px;
      padding: 0.6rem 1rem;

      span {
        font-size: 1.1rem;
        padding-right: 1rem;
      }
      .custom-icon {
        font-size: 1rem;
      }
    }
  }
  .primary {
    .contents-wrapper {
      background-color: ${(props) => props.theme.colors.mainPurple};

      span {
        color: ${(props) => props.theme.colors.white};
      }

      .icon-arrow-right {
        color: ${(props) => props.theme.colors.white};
      }
    }

    ${onHoverFocus(() => {
      return css`
        outline: none;

        .contents-wrapper {
          background-color: ${(props) => props.theme.colors.mainBlue};
        }
      `;
    })};
  }
  .secondary {
    .contents-wrapper {
      background-color: ${(props) => props.theme.colors.white};

      span {
        color: ${(props) => props.theme.colors.mainPurple};
      }

      .icon-arrow-right {
        color: ${(props) => props.theme.colors.mainPurple};
      }
    }
    ${onHoverFocus(() => {
      return css`
        outline: none;

        .contents-wrapper {
          background-color: ${(props) => props.theme.colors.mainBlue};

          span,
          .icon-arrow-right {
            color: ${(props) => props.theme.colors.white};
          }
        }
      `;
    })};
  }
`;
