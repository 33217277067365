import React from "react";

import Logo from "../Logo";
import { UspWrapper } from "./style";

const Usp = (props) => {
  return (
    <UspWrapper className="usp-wrapper">
      <div className="usp-inner">
        <div className="item-wrapper speed-wrapper">
          <div className="top-wrapper">
            <div className="title-wrapper">
              <Logo text="Speed" logoClass="yellow" />
            </div>
          </div>
          <div className="bottom-wrapper">
            <div className="bottom-inner">
              <p>
                We utilise modern technology to ensure your website is blazing fast so that your online users stay and
                achieve what they want without frustration.
              </p>
            </div>
          </div>
        </div>
        <div className="item-wrapper secure-wrapper">
          <div className="top-wrapper">
            <div className="title-wrapper">
              <Logo text="Secure" logoClass="blue" />
            </div>
          </div>
          <div className="bottom-wrapper">
            <div className="bottom-inner">
              <p>
                A static-generated site solely comprises static files, with no database for an attacker to exploit by
                injecting malicious code. So, vulnerability to a cyber attack is minimal.
              </p>
            </div>
          </div>
        </div>
        <div className="item-wrapper accessible-wrapper">
          <div className="top-wrapper">
            <div className="title-wrapper">
              <Logo text="Accessibility" logoClass="pink" />
            </div>
          </div>
          <div className="bottom-wrapper">
            <div className="bottom-inner">
              <p>
                We believe every website should be available for everyone. We follow the Web Content Accessibility
                Guidelines (WCAG) 2.1.
              </p>
            </div>
          </div>
        </div>
      </div>
    </UspWrapper>
  );
};

export default Usp;
