import styled from "styled-components";

import { flex, backgroundImageProperties } from "../Global/style-variables";
import bannerImage from "../../resources/images/home-madebyhat.png";

export const BannerWrapper = styled.section`
  padding: 0;
  z-index: 0;

  .background {
    background-color: ${(props) => props.theme.colors.mainBG};
    position: absolute;
    width: 100%;
    height: 120%;
    top: -190px;
    bottom: 0;
    transform: skewY(-12deg);
    z-index: -1;
    pointer-events: none;

    &:before {
      background-color: ${(props) => props.theme.colors.mainPurple};
      opacity: 0.8;
      position: absolute;
      content: "";
      width: 450px;
      height: 40px;
      bottom: -40px;
      left: 0;
    }
    &:after {
      background-color: #084ca1;
      opacity: 0.8;
      position: absolute;
      content: "";
      width: 170px;
      height: 40px;
      bottom: -60px;
      left: 0;
    }
    @media (max-width: 810px) {
      &:before {
        width: 400px;
        height: 35px;
        bottom: -35px;
      }
      &:after {
        bottom: -55px;
      }
    }
    @media (max-width: 690px) {
      top: -250px;

      &:before {
        width: 350px;
      }
    }
    @media (max-width: 425px) {
      &:before {
        width: 300px;
      }
      &:after {
        height: 35px;
        bottom: -50px;
      }
    }
    @media (max-width: 320px) {
      &:before {
        width: 250px;
        height: 30px;
        bottom: -30px;
      }
      &:after {
        width: 150px;
        height: 30px;
        bottom: -45px;
      }
    }
  }

  .inner-wrapper {
    display: flex;
    padding: 5rem 6rem;

    .col {
      &.col-1 {
        ${flex("0 0 60%")};
        margin-right: 1rem;

        .top-wrapper {
          margin-bottom: 3rem;

          h1 {
            color: ${(props) => props.theme.colors.mainBlue};
            line-height: 3.8rem;
            margin-top: 0;
          }
          p {
            color: ${(props) => props.theme.colors.mainPurple};
            font-size: 1.5rem;
            max-width: 630px;
          }
        }
        .btn-wrapper {
          display: flex;

          @media (max-width: 930px) {
            flex-direction: column;
            max-width: 300px;

            > div {
              margin-bottom: 0.4rem;

              a {
                display: block;
              }
            }
          }
        }
      }

      &.col-2 {
        ${flex("1")};

        #banner-image {
          ${backgroundImageProperties};
          background-image: url(${bannerImage});
          background-position: bottom;
          background-size: contain;
          height: 390px;
          margin-top: -2rem;
        }
      }
    }

    @media (max-width: 880px) {
      padding: 3rem 6rem 10rem;
    }
    @media (max-width: 810px) {
      padding: 3rem 3rem 10rem;
    }
    @media (max-width: 690px) {
      flex-direction: column;

      .col {
        &.col-1 {
          margin-right: 0;

          .btn-wrapper > div {
            margin-right: 0;
          }
        }

        &.col-2 #banner-image {
          background-position: right;
          height: 295px;
          margin-top: -5rem;

          @media (max-width: 530px) {
            margin-top: 0;
          }
        }
      }
    }
    @media (max-width: 440px) {
      padding: 3rem 1rem 8rem;

      .col {
        &.col-2 #banner-image {
          background-position: center;
        }
      }
    }
  }
`;
