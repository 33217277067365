import styled, { css } from "styled-components";

import { flex, displayFlex, hoverTransition, onHoverFocus } from "../Global/style-variables";

export const ContactFormWrapper = styled.div`
  .form-inner-wrapper {
    .col-wrapper {
      ${displayFlex("center, unset")};

      .input-wrapper {
        ${flex("1 0 44%")};
        padding: 2rem 1rem 1rem;
        transition: all 0.3s;

        &:focus-within {
          transform: scale(1.05, 1.05);
        }

        .custom-label {
          display: block;
          opacity: 1;
          transform: translateY(-2rem);
          transform-origin: 0 0;
          transition: all 0.3s;
          pointer-events: none;
        }
        input,
        textarea {
          box-shadow: none;
          border-radius: 0px;
          border: none;
          border-bottom: 2px solid ${(props) => props.theme.colors.mainPurple};
          width: 100%;
          transition: all 0.5s;

          &::placeholder {
            color: transparent;
          }
          &:focus {
            box-shadow: none;
            outline: none;
            border-bottom: 2px solid ${(props) => props.theme.colors.logoOrange};

            & + .custom-label {
              transform: translateY(-3rem) scale(0.8);
            }
          }
          &:not(:placeholder-shown) + .custom-label {
            transform: translateY(-3rem) scale(0.8);
          }
        }

        /* For the custom dropdown */
        .listbox-wrapper {
          position: relative;

          /* For the chosen option */
          .chosen-option {
            ${hoverTransition};
            ${displayFlex("space-between", "unset")};
            border-bottom: 2px solid ${(props) => props.theme.colors.mainPurple};
            padding-bottom: 0.5rem;
            margin-top: -0.4rem;
            cursor: pointer;
            outline: none;

            span {
              pointer-events: none;

              &.indicator {
                ${hoverTransition};
                ${displayFlex("center", "center")};
                transform: rotate(90deg);
                position: absolute;
                right: 10px;
                width: 25px;
                height: 25px;
                border-radius: 50px;
              }
            }

            ${onHoverFocus(() => {
              return css`
                span.indicator {
                  color: white;
                  background-color: ${(props) => props.theme.colors.mainPurple};
                }
              `;
            })}
          }

          /* For the list wrapper */
          #list-wrapper {
            ${hoverTransition};
            position: absolute;
            visibility: hidden;
            opacity: 0;
            top: 100%;
            left: 0;
            right: 0;
            color: white;
            border-radius: 0 0 6px 6px;
            background-color: ${(props) => props.theme.colors.mainPurple};
            padding: 0.7rem;

            .list-option {
              ${hoverTransition};
              border-radius: 4px;
              padding: 0.4rem;
              cursor: pointer;

              &.selected {
                color: ${(props) => props.theme.colors.mainPurple};
                background-color: white;
              }

              ${onHoverFocus(() => {
                return css`
                  color: ${(props) => props.theme.colors.mainPurple};
                  background-color: white;
                `;
              })}
            }
          }

          &.active-select {
            .chosen-option span.indicator {
              transform: rotate(-90deg);
            }
            #list-wrapper {
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }

      &.tel-reason-wrap {
        @media (max-width: 445px) {
          flex-direction: column;
        }
      }
    }

    .checkbox-button-wrap {
      ${displayFlex("space-between", "unset")};
      padding: 0 1.5rem 0 1rem;

      .checkBox-wrap {
        ${displayFlex("center", "center")};

        .new-box {
          position: relative;
          width: 20px;
          height: 20px;
          margin-left: 1rem;
          border: 2px solid ${(props) => props.theme.colors.mainPurple};

          .sendForm-input {
            appearance: none;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            outline: none;

            &:checked {
              height: 10px;
              width: 14px;
              border-left: 2px solid ${(props) => props.theme.colors.mainPurple};
              border-bottom: 2px solid ${(props) => props.theme.colors.mainPurple};
              transform: rotate(-45deg);
            }
          }
        }
      }
    }

    .submit-btn {
      ${hoverTransition};
      color: ${(props) => props.theme.colors.white};
      border: none;
      border-radius: 4px;
      border: 1px solid ${(props) => props.theme.colors.mainPurple};
      background-color: ${(props) => props.theme.colors.mainPurple};
      padding: 0.5rem 1.3rem;

      ${onHoverFocus(() => {
        return css`
          color: ${(props) => props.theme.colors.mainPurple};
          background-color: ${(props) => props.theme.colors.white};
        `;
      })}
    }

    @media only screen and (max-width: 425px) {
      .name-email-wrap {
        flex-direction: column;
      }
      .checkbox-button-wrap {
        flex-direction: column;

        .checkBox-wrap {
          justify-content: flex-start;
          margin-bottom: 20px;
        }
      }
    }
    @media only screen and (max-width: 500px) {
      .checkbox-button-wrap {
        #form-button-styling {
          justify-content: flex-end;
        }
      }
    }
  }
`;
