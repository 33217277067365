import styled from "styled-components";

import { displayFlex, backgroundImageProperties } from "../Global/style-variables";
import patternBG from "../../resources/images/pattern-bg.svg";

export const WhyPanelWrapper = styled.section`
  position: relative;
  margin-top: 4rem;
  z-index: 0;

  &:before {
    ${backgroundImageProperties};
    background-image: url(${patternBG});
    position: absolute;
    content: "";
    width: 400px;
    height: 400px;
    top: -20px;
    left: -70px;
  }

  .inner-wrapper {
    .text-wrapper {
      ${displayFlex("center", "center")};
      flex-direction: column;
      text-align: center;
      margin-bottom: 4rem;

      h2 {
        font-weight: bold;
        font-size: 1.2rem;
        line-height: 0;
      }
      h3 {
        color: ${(props) => props.theme.colors.mainBlue};
        font-weight: bold;
        line-height: 2.5rem;
      }
      p {
        max-width: 600px;
        font-size: 1.2rem;
        text-align: center;
      }

      @media (max-width: 500px) {
        text-align: left;
      }
    }
    .usp-wrapper {
      margin-bottom: 4rem;
    }
    .btn-wrapper {
      text-align: center;

      .primary {
        margin: 0 auto;
      }
    }
  }

  @media only screen and (max-width: 880px) {
    &:before {
      top: -80px;
    }
    .inner-wrapper {
      .text-wrapper {
        p {
          max-width: 500px;
        }
      }
      .usp-wrapper {
        margin-bottom: 0;
      }
    }
  }
  @media only screen and (max-width: 535px) {
    &:before {
      width: 320px;
      height: 320px;
    }
    .inner-wrapper {
      .text-wrapper {
        h3 {
          font-size: 1.7rem;
        }
      }
    }
  }
  @media only screen and (max-width: 500px) {
    &:before {
      width: 250px;
      height: 250px;
      top: -50px;
    }
    .inner-wrapper {
      .text-wrapper {
        align-items: flex-start;

        p {
          text-align: left;
        }
      }
    }
  }
  @media only screen and (max-width: 375px) {
    &:before {
      width: 190px;
      height: 190px;
    }
    .inner-wrapper {
      .text-wrapper {
        h3 {
          font-size: 1.6rem;
        }
      }
    }
  }
`;
