import styled from "styled-components";

import { flex, displayFlex, backgroundImageProperties } from "../Global/style-variables";
import logo from "../../resources/images/madebyhat-logo-transparent.png";
import aboutImage from "../../resources/images/about-madebyhat.png";

export const AboutPanelWrapper = styled.section`
  margin-bottom: 5rem;
  z-index: 0;

  &:before {
    background-color: ${(props) => props.theme.colors.mainBG};
    position: absolute;
    content: "";
    width: 100%;
    height: 300px;
    bottom: -150px;
    left: 0;
  }
  .inner-wrapper {
    background-color: ${(props) => props.theme.colors.mainBlue};
    position: relative;
    display: flex;
    width: 100%;
    border-radius: 20px;
    padding: 0;
    box-shadow: -1px 6px 20px 3px rgba(0, 0, 0, 0.2);

    .background {
      content: "";
      position: absolute;
      background-color: ${(props) => props.theme.colors.white};
      width: 40%;
      height: 100%;
      transform: skew(-12deg);
      top: 0;
      left: 45px;
      border-bottom-left-radius: 20px;
      z-index: 2;
    }
    .left-wrapper {
      ${flex("0 0 40%")}
      background-color: ${(props) => props.theme.colors.white};
      border: 1px solid transparent;
      border-radius: 20px 0 0 20px;
      z-index: 2;

      .left-inner {
        background-color: ${(props) => props.theme.colors.white};
        width: 100%;
        height: 100%;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        z-index: 2;
      }
      .logo-wrapper {
        display: flex;
        padding: 1rem;
        transform: skew(0deg);

        .logo-image {
          ${backgroundImageProperties};
          background-image: url(${logo});
          transform: skew(0deg);
          width: 70px;
          height: 70px;
          margin-right: 1rem;
        }
      }
      #about-image {
        ${backgroundImageProperties};
        background-size: contain;
        background-image: url(${aboutImage});
        transform: skew(0deg);
        width: 70%;
        height: 220px;
        margin: 0 auto;
        margin-bottom: 2rem;
      }
    }
    .right-wrapper {
      ${flex("0 0 60%")}
      background-color: ${(props) => props.theme.colors.mainBlue};
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      z-index: 1;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        background-color: ${(props) => props.theme.colors.white};
        width: 75px;
        height: 100%;
        top: 0;
        left: -37px;
        transform: skew(-12deg);
      }

      .text-wrapper {
        color: ${(props) => props.theme.colors.white};
        ${displayFlex("center", "center")}
        flex-direction: column;
        max-width: 65%;
        height: 100%;
        margin: 0 auto;
      }
    }
  }

  @media only screen and (max-width: 880px) {
    .inner-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: ${(props) => props.theme.colors.mainBlue};
      border: transparent;
      border-radius: 20px;

      .background {
        display: none;
      }
      .left-wrapper {
        ${flex("1 auto")}
        width: 100%;
        background-color: ${(props) => props.theme.colors.white};
        border: none;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-left-radius: 0;
        z-index: 1;
        position: relative;

        .left-inner {
          width: auto;
          padding-top: 2rem;
          padding-left: 2rem;
          padding-right: 2rem;
          padding-bottom: 6rem;
          border-top-right-radius: 20px;
          border: none;

          .logo-wrapper {
            h2 {
              font-size: 2rem;
            }
          }
        }
      }
      .right-wrapper {
        ${flex("1 auto")}
        width: 100%;
        background-color: ${(props) => props.theme.colors.mainBlue};
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        z-index: 1;

        &:before {
          content: "";
          position: absolute;
          background-color: ${(props) => props.theme.colors.mainBlue};
          border-bottom-left-radius: 20px;
          width: 100%;
          height: 180px;
          transform: skewY(-12deg);
          top: -85px;
          z-index: -1;
          left: 0;
        }
        .text-wrapper {
          padding: 3rem 2rem;
          margin-top: -4rem;
          margin-bottom: 0rem;

          p {
            color: ${(props) => props.theme.colors.white};
          }
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .inner-wrapper {
      .right-wrapper {
        .text-wrapper {
          max-width: 75%;
        }
      }
    }
  }
  @media only screen and (max-width: 425px) {
    .inner-wrapper {
      .right-wrapper {
        .text-wrapper {
          max-width: 85%;
        }
      }
    }
  }
`;
