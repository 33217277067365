import React from "react";
import Layout from "../components/Layout/index";
import SEO from "../components/Seo";
import Banner from "../components/BannerPanel";
import WhyPanel from "../components/WhyPanel";
import AboutPanel from "../components/AboutPanel";
import PortfolioPanel from "../components/PortfolioPanel";
import ContactPanel from "../components/ContactPanel";

const Home = () => {
  return (
    <Layout>
      <SEO title="madebyHaT" />
      <Banner />
      <WhyPanel />
      <AboutPanel />
      <PortfolioPanel />
      <ContactPanel />
      {/*  
      <i className="custom-icon icon-up-open-big">&#xe802;</i> 
      <i className="custom-icon icon-arrow-left">&#xe804;</i> 
      <i className="custom-icon icon-check-circle">&#xf06d;</i> 
       */}
    </Layout>
  );
};

export default Home;
