import React from "react";

import Portfolio from "../Portfolio";
import Button from "../Button";
import { PortfolioPanelWrapper } from "./style";

const PortfolioPanel = () => {
  return (
    <PortfolioPanelWrapper id="portfolio">
      <div className="background"></div>
      <div className="inner-wrapper">
        <div className="text-wrapper">
          <div className="text-inner">
            <h2>Our recent projects</h2>
            <p>Integrity in every build. Performance you can measure.</p>
          </div>
        </div>

        <Portfolio />

        <div className="buttons-wrapper">
          <Button
            linkTo="#contact"
            buttonWrapClass="inner-btn-wrapper"
            buttonText="Request a free consultation"
            buttonClass="primary"
            id="left"
          />
        </div>
      </div>
    </PortfolioPanelWrapper>
  );
};

export default PortfolioPanel;
