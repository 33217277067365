import React, { Component } from "react";

import { ContactFormWrapper } from "./style";
import { master } from "../../resources";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

class ContactForm extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      selectOption: "enquiry-type",
      message: "",
      thankyou: false,
      error: {
        nameError: false,
        emailError: false,
        selectError: false,
        messageError: false,
      },
    };
  }

  onChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { name, email, selectOption, message } = this.state;
    let hasError = false;

    if (name === "" || email === "" || selectOption === "enquiry-type" || message === "") {
      hasError = true;
      if (name === "") {
        this.setState({ error: { nameError: true } });
      } else if (email === "") {
        this.setState({ error: { emailError: true } });
      } else if (selectOption === "enquiry-type") {
        this.setState({ error: { selectError: true } });
      } else if (message === "") {
        this.setState({ error: { messageError: true } });
      }
    }

    if (!hasError) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...this.state }),
      })
        .then(() => this.setState({ thankyou: true }))
        .catch((error) => {
          console.log("ooops something went wrong..");
        });
    }
  };

  // For Dropdown Menu
  handleSelectOpen = (event) => {
    event.preventDefault();
    const currentElement = event.target;
    const parentElement = currentElement.parentElement;

    if (parentElement.classList.contains("active-select")) {
      currentElement.parentElement.classList.remove("active-select");
    } else {
      currentElement.parentElement.classList.add("active-select");
    }
  };

  handleSelectChosen = (event, value) => {
    event.preventDefault();
    const currentElement = event.target;
    this.setState({ selectOption: value });

    currentElement.parentElement.parentElement.classList.remove("active-select");
  };

  render() {
    const { name, email, selectOption, message, thankyou, error } = this.state;
    const isInvalid = name === "" || email === "" || selectOption === "" || selectOption === "select" || message === "";

    const selectOptionEdit = selectOption
      .split("-")
      .map((v) => v.charAt(0).toUpperCase() + v.slice(1))
      .join(" ");

    const { contactEnquiryType } = master;

    return (
      <ContactFormWrapper>
        <div className="form-wrapper">
          {thankyou ? (
            <p className="success-message">Your message has been sent!</p>
          ) : (
            <form
              onSubmit={(event) => this.handleSubmit(event)}
              name="contact"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <div className="form-inner-wrapper">
                {/* Name & Email row */}
                <div className="col-wrapper name-email-wrap">
                  <div className="input-wrapper name-wrap">
                    <label className="screen-reader-text" htmlFor="name">
                      Name
                    </label>
                    <input
                      id="name"
                      className="the-value-capture"
                      type="text"
                      name="name"
                      placeholder="Full Name"
                      value={name}
                      onChange={this.onChange}
                    />
                    <div className="custom-label" aria-hidden="true">
                      Name
                    </div>

                    {/* Error message */}
                    {error["nameError"] && (
                      <div className="error-message">
                        <p>Name field is required</p>
                      </div>
                    )}
                  </div>

                  <div className="input-wrapper email-wrap">
                    <label className="screen-reader-text" htmlFor="email">
                      Email
                    </label>
                    <input
                      id="email"
                      className="the-value-capture"
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={email}
                      onChange={this.onChange}
                    />
                    <div className="custom-label" aria-hidden="true">
                      Email
                    </div>

                    {/* Error message */}
                    {error["emailError"] && (
                      <div className="error-message">
                        <p>Email field is required</p>
                      </div>
                    )}
                  </div>
                </div>

                {/* Tel Number & Dropdown row */}
                <div className="col-wrapper tel-reason-wrap">
                  <div className="input-wrapper tel-wrap">
                    <label className="screen-reader-text" htmlFor="tel">
                      Contact Number
                    </label>
                    <input
                      id="tel"
                      className="the-value-capture"
                      type="tel"
                      name="tel"
                      placeholder="Contact Number"
                      onChange={this.onChange}
                    />
                    <div className="custom-label" aria-hidden="true">
                      Contact Number
                    </div>

                    {/* Error message */}
                    {error["nameError"] && (
                      <div className="error-message">
                        <p>Contact Number field is required</p>
                      </div>
                    )}
                  </div>

                  {/* Reason Dropdown Menu */}
                  <div className="input-wrapper reason-wrap">
                    <div className="screen-reader-text" id="reason-dropbox" role="label">
                      What are you enquiring about?
                    </div>

                    <div className="listbox-wrapper">
                      {/* For the chosen option */}
                      <div
                        className="the-value-capture chosen-option"
                        onClick={(e) => this.handleSelectOpen(e)}
                        onKeyPress={(e) => this.handleSelectOpen(e)}
                        tabIndex="0"
                      >
                        <span>{selectOption === "enquiry-type" ? "Enquiry Type" : selectOptionEdit}</span>
                        <span className="indicator">
                          <i className="custom-icon icon-arrow-right">&#xe800;</i>
                        </span>
                      </div>

                      {/* For the list options */}
                      <div
                        role="listbox"
                        tabIndex="0"
                        id="list-wrapper"
                        aria-labelledby="reason-dropbox"
                        aria-activedescendant={selectOption === "enquiry-type" ? "" : selectOption}
                      >
                        {contactEnquiryType.map((value, index) => {
                          const displayValue = value
                            .split("-")
                            .map((v) => v.charAt(0).toUpperCase() + v.slice(1))
                            .join(" ");
                          const isActive = value === selectOption;

                          return (
                            <div
                              key={index}
                              role="option"
                              id={value}
                              className={`list-option ${isActive && `selected`}`}
                              tabIndex="0"
                              aria-selected={isActive}
                              onClick={(e) => this.handleSelectChosen(e, value)}
                              onKeyPress={(e) => this.handleSelectChosen(e, value)}
                            >
                              {displayValue}
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    {error["emailError"] && (
                      <div className="error-message">
                        <p>This field is required</p>
                      </div>
                    )}
                  </div>
                </div>

                {/* Textarea row */}
                <div className="col-wrapper textarea-outer-wrap">
                  <div className="input-wrapper textarea-wrap">
                    <label htmlFor="message">How Can We Help?</label>

                    <textarea
                      id="message"
                      name="message"
                      rows="4"
                      placeholder="Message"
                      value={message}
                      onChange={this.onChange}
                    ></textarea>

                    {error["messageError"] && (
                      <div className="error-message">
                        <p>Message field is required</p>
                      </div>
                    )}
                  </div>
                </div>

                {/* Checkbox & submit button row */}
                <div className="checkbox-button-wrap">
                  <div className="checkBox-wrap">
                    <label className="screen-reader sendForm-label" htmlFor="checkbox-sendForm">
                      Request a Web Requirements Form
                    </label>
                    <div className="new-box">
                      <input
                        id="checkbox-sendForm"
                        type="checkBox"
                        name="send-form"
                        // value={checkBox}
                        className="sendForm-input"
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                  <input type="hidden" name="form-name" value="contact" />

                  <button type="submit" disabled={isInvalid} className="submit-btn">
                    <div className="contents-wrapper">
                      <span>Submit</span>
                    </div>
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </ContactFormWrapper>
    );
  }
}

export default ContactForm;
