import React, { Fragment } from "react";
import { BannerWrapper } from "./style";
import Button from "../Button";

const Banner = () => {
  return (
    <Fragment>
      <BannerWrapper>
        <div className="background"></div>
        <div className="inner-wrapper">
          <div className="col col-1">
            <div className="col-inner">
              <div className="top-wrapper">
                <h1>Maximise Your Online Presence</h1>
                <p>We build websites that enable you to communicate better with your target audiences</p>
              </div>
              <div className="btn-wrapper">
                <Button linkTo="/#contact" buttonText="Request a free consultation" buttonClass="primary" id="left" />
                <Button linkTo="/#services" buttonText="Find out more" buttonClass="secondary" id="right" />
              </div>
            </div>
          </div>

          <div className="col col-2">
            <div id="banner-image"></div>
          </div>
        </div>
      </BannerWrapper>
    </Fragment>
  );
};

export default Banner;
