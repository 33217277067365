import React from "react";

import ContactForm from "../ContactForm/index.js";

import { ContactPanelWrapper } from "./style";

const ContactPanel = () => {
  return (
    <ContactPanelWrapper id="contact">
      <div className="inner-wrapper">
        <div className="col-1">
          <div className="col-inner">
            <div className="contact-image"></div>
          </div>
        </div>
        <div className="col-2">
          <div className="col-inner">
            <div className="text-wrapper">
              <h2>Contact us</h2>
              <p>Let's find out how we can improve your online presence!</p>
            </div>

            <ContactForm />
          </div>
        </div>
      </div>
    </ContactPanelWrapper>
  );
};

export default ContactPanel;
