import React, { Fragment } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import { ButtonWrapper } from "./style";

const Button = ({ buttonText, buttonClass, linkTo, buttonWrapClass }) => {
  const contents = () => {
    return (
      <Fragment>
        <div className="contents-wrapper">
          <span>{buttonText}</span>
          <i className="custom-icon icon-arrow-right">&#xe800;</i>
        </div>
      </Fragment>
    );
  };

  return (
    <ButtonWrapper className={buttonWrapClass}>
      {linkTo ? (
        <Link to={linkTo} className={buttonClass}>
          {contents()}
        </Link>
      ) : (
        <button className={buttonClass}>{contents()}</button>
      )}
    </ButtonWrapper>
  );
};

Button.propTypes = {
  buttonText: PropTypes.string,
  buttonClass: PropTypes.string,
  linkTo: PropTypes.string,
  buttonWrapClass: PropTypes.string,
};
Button.defaultProps = {
  buttonText: "",
  buttonClass: "",
  linkTo: "",
  buttonWrapClass: "",
};

export default Button;
