import styled from "styled-components";

import { flex, displayFlex, backgroundImageProperties } from "../Global/style-variables";

import speedIcon from "../../resources/images/speed.png";
import securityIcon from "../../resources/images/security-lock.png";
import accessibilityIcon from "../../resources/images/accessibility.png";

export const UspWrapper = styled.div`
  .usp-inner {
    ${displayFlex("space-between", "center")};

    .item-wrapper {
      padding: 0 2rem;
      flex: 1 0 27%;

      .top-wrapper {
        position: relative;

        &:after {
          ${backgroundImageProperties};
          position: absolute;
          content: "";
        }

        &:after {
          opacity: 0.6;
        }

        .title-wrapper {
          ${displayFlex("flex-start", "center")};
        }
      }

      .bottom-wrapper p {
        font-size: 1rem;
      }
    }
    .speed-wrapper {
      border-left: 3px solid ${(props) => props.theme.colors.logoOrange};

      .top-wrapper {
        &:after {
          background-image: url(${speedIcon});
          width: 60px;
          height: 32px;
          top: 0;
          right: 0;
        }
      }
    }
    .secure-wrapper {
      border-left: 3px solid ${(props) => props.theme.colors.logoBlue};

      .top-wrapper {
        &:after {
          background-image: url(${securityIcon});
          width: 30px;
          height: 40px;
          top: 0;
          right: 0;
        }
      }
    }
    .accessible-wrapper {
      border-left: 3px solid ${(props) => props.theme.colors.logoPink};

      .top-wrapper {
        &:after {
          background-image: url(${accessibilityIcon});
          width: 33px;
          height: 36px;
          top: 0;
          right: 0;
        }
      }
    }
  }
  @media only screen and (max-width: 980px) {
    .usp-inner {
      .speed-wrapper {
        .top-wrapper {
          &:after {
            width: 50px;
            height: 27px;
          }
        }
      }
      .secure-wrapper {
        .top-wrapper {
          &:after {
            width: 27px;
            height: 35px;
          }
        }
      }
      .accessible-wrapper {
        .top-wrapper {
          &:after {
            width: 30px;
            height: 32px;
          }
        }
      }
    }
  }
  // changing styling from 3 column to 1 each //
  @media only screen and (max-width: 880px) {
    .usp-inner {
      flex-direction: column;
      max-width: 600px;
      margin: 0 auto;

      .item-wrapper {
        margin-bottom: 4rem;
        ${flex("auto")}
        width: 100%;
      }
    }
  }
`;
