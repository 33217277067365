import styled from "styled-components";

import { displayFlex, backgroundImageProperties } from "../Global/style-variables";
import patternBG from "../../resources/images/pattern-bg.svg";

export const PortfolioPanelWrapper = styled.section`
  background-color: ${(props) => props.theme.colors.mainBG};
  z-index: 1;

  .background {
    background-color: ${(props) => props.theme.colors.mainBG};
    position: absolute;
    width: 100%;
    height: 320px;
    bottom: -142px;
    left: 0;
    transform: skewY(-12deg);
    z-index: 0;

    &:before {
      background-color: ${(props) => props.theme.colors.mainPurple};
      opacity: 0.8;
      position: absolute;
      content: "";
      width: 500px;
      height: 40px;
      bottom: 0;
      left: 0;
    }
    &:after {
      background-color: #084ca1;
      opacity: 0.8;
      position: absolute;
      content: "";
      width: 170px;
      height: 40px;
      bottom: 20px;
      left: 0;
    }
  }

  .inner-wrapper {
    position: relative;
    padding-bottom: 0;

    &:before {
      ${backgroundImageProperties};
      background-image: url(${patternBG});
      transform: rotate(180deg);
      position: absolute;
      content: "";
      width: 450px;
      height: 400px;
      top: -200px;
      right: -150px;
    }

    .text-wrapper {
      .text-inner {
        h2 {
          font-size: 1.2rem;
          font-weight: bold;
        }
        p {
          color: ${(props) => props.theme.colors.mainBlue};
          font-size: 2.2rem;
          font-weight: bold;
          max-width: 470px;
        }
      }
    }
    .buttons-wrapper {
      ${displayFlex("center", "center")};
      margin-top: 4rem;

      .inner-btn-wrapper {
        margin: 0;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .background {
      &:before {
        width: 400px;
        height: 35px;
      }
      &:after {
        height: 35px;
        bottom: 15px;
      }
    }
  }
  @media only screen and (max-width: 985px) {
    .background {
      &:before {
        width: 300px;
        height: 30px;
      }
    }
  }
  @media only screen and (max-width: 880px) {
    .background {
      &:before {
        width: 250px;
        height: 25px;
      }
      &:after {
        height: 25px;
        bottom: 10px;
      }
    }
    .inner-wrapper {
      &:before {
        width: 380px;
        height: 350px;
      }
      .text-wrapper {
        .text-inner {
          margin-bottom: 3rem;

          h2 {
            font-size: 1.2rem;
            font-weight: bold;
          }
        }
      }
      .buttons-wrapper {
        ${displayFlex("center", "center")};
      }
    }
  }
  @media only screen and (max-width: 770px) {
    .background {
      &:before {
        width: 200px;
      }
      &:after {
        width: 100px;
      }
    }
  }
  @media only screen and (max-width: 550px) {
    .background {
      &:before {
        height: 20px;
        width: 170px;
      }
      &:after {
        height: 20px;
        bottom: 10px;
        width: 70px;
      }
    }
    .inner-wrapper {
      &:before {
        width: 280px;
        height: 280px;
        top: -150px;
        right: -100px;
      }
    }
  }
  @media only screen and (max-width: 375px) {
    .background {
      padding-bottom: 9rem;
      &:before {
        height: 15px;
        width: 120px;
      }
      &:after {
        height: 15px;
        width: 50px;
        bottom: 7px;
      }
    }
  }
`;
