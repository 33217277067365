import styled from "styled-components";

import { flex, displayFlex, backgroundImageProperties } from "../Global/style-variables";

export const PortfolioWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.mainBG};

  .portfolio-inner {
    .slick-prev,
    .slick-next {
      &:before {
        color: ${(props) => props.theme.colors.mainBlue};
        font-size: 30px;
      }
    }

    .portfolio-group {
      display: flex !important;

      .col-1 {
        ${flex("0 0 50%")};

        .col-inner {
          color: ${(props) => props.theme.colors.mainBlue};

          .company-info {
            display: inline-block;
            border-left: 2px solid;
            padding-left: 3rem;
            margin-bottom: 2rem;

            h3 {
              color: ${(props) => props.theme.colors.logoPurple};
              line-height: 2.6rem;
            }
            .company-brief {
              font-size: 1.3rem;
            }
          }
          .summary-points {
            padding-left: 3rem;
            margin-bottom: 2rem;

            ul {
              li {
                display: flex;
                margin-bottom: 1rem;

                .icon-wrapper {
                  ${displayFlex("center", "center")};
                  ${flex("0 0 25px")};
                  background-color: ${(props) => props.theme.colors.mainPurple};
                  border-radius: 50px;
                  height: 25px;

                  .icon-check {
                    color: ${(props) => props.theme.colors.white};
                    font-size: 0.8rem;
                    text-align: center;
                  }
                }
                .list-text {
                  margin-left: 2rem;
                }
              }
            }
          }
          .quote-wrapper {
            padding-left: 3rem;

            .quote-top {
              q {
                font-style: italic;
              }
            }
            .quote-bottom {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
            }
          }
        }
      }
      .col-2 {
        ${flex("0 0 50%")};
        margin-bottom: 4rem;

        .col-inner {
          .image-wrapper {
            box-shadow: 0 30px 30px -12px rgba(50, 50, 93, 0.25);
            margin: 0 auto;
            width: 75%;
            position: relative;
            overflow-x: auto;
            overflow-y: auto;
            height: 550px;

            img {
              ${backgroundImageProperties};
              background-size: contain;
              width: 100%;
              margin: 0 auto;
              position: relative;
            }
          }
        }
      }
    }
    .slick-dots button:before {
      font-size: 10px;
    }

    @media only screen and (max-width: 880px) {
      .portfolio-group {
        flex-direction: column;

        .col-1 {
          order: 1;

          .col-inner {
            .icon-wrapper {
              width: 20px;
              height: 20px;

              .icon-check {
                font-size: 1rem;
              }
            }
          }
        }
        .col-2 {
          margin-bottom: 6rem;
        }
      }
    }
    @media only screen and (max-width: 560px) {
      .image-wrapper {
        height: 450px;
      }
      .slick-dots button:before {
        font-size: 20px;
      }
    }
    @media only screen and (max-width: 425px) {
      .image-wrapper {
        height: 370px;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .background {
      &:before {
        width: 400px;
        height: 35px;
      }
      &:after {
        height: 35px;
        bottom: 35px;
      }
    }
  }
`;
