import styled from "styled-components";

import { flex, displayFlex, backgroundImageProperties } from "../Global/style-variables";
import contactImage from "../../resources/images/contact-banner-image.png";
import shapeElement from "../../resources/images/shape-element.svg";

export const ContactPanelWrapper = styled.section`
  margin-top: 8rem;

  &:after {
    position: absolute;
    content: "";
    background-image: url(${shapeElement});
    background-size: 100%;
    background-repeat: no-repeat;
    width: 200px;
    height: 300px;
    top: -17%;
    right: 0;

    @media (max-width: 600px) {
      background-size: 70%;
      right: -60px;
    }
    @media (max-width: 500px) {
      background-size: 60%;
      right: -80px;
      top: -10%;
    }
  }

  .inner-wrapper {
    ${displayFlex("center", "center")}

    .col-1 {
      ${flex("1")};

      .col-inner {
        ${displayFlex("center", "center")}
        position: relative;

        .contact-image {
          ${backgroundImageProperties};
          background-image: url(${contactImage});
          background-size: contain;
          position: absolute;
          width: 300px;
          height: 300px;
          top: -160px;
        }
      }
    }
    .col-2 {
      ${flex("0 0 60%")};

      .col-inner {
        ${displayFlex("enter", "cflex-start")}
        flex-direction: column;
        padding: 2rem;

        .text-wrapper {
          h2 {
            font-size: 2rem;
            color: ${(props) => props.theme.colors.mainBlue};
            margin-top: 0;
          }
        }
      }
    }

    @media (max-width: 930px) {
      flex-direction: column;

      .col-1,
      .col-2 {
        ${flex("auto")}
        width: 100%;

        .col-inner {
          padding: 0;
        }
      }
      .col-1 {
        display: none;
      }

      .checkbox-button-wrap {
        flex-direction: column;
        margin-top: 2rem;

        .submit-btn {
          margin-top: 2rem;
        }
      }
    }
  }

  @media (max-width: 930px) {
    margin-top: 12rem;
  }
`;
