import React from "react";

import Logo from "../Logo";
import { AboutPanelWrapper } from "./style";

const AboutPanel = () => {
  return (
    <AboutPanelWrapper id="about">
      <div className="inner-wrapper">
        <div className="left-wrapper">
          <div className="left-inner">
            <Logo logoClass="basic" text="madebyHaT?" />
            <div id="about-image"></div>
          </div>
        </div>
        <div className="right-wrapper">
          <div className="text-wrapper">
            <p>
              We are a power couple specialising in website and web application development with a combined industry
              experience of 7 years.
            </p>
            <p>
              We care about you and your needs. We will work right beside you throughout the entire process from
              planning to launching the site.
            </p>
          </div>
        </div>
      </div>
    </AboutPanelWrapper>
  );
};

export default AboutPanel;
